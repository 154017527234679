<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="grid">
        <div class="col-12">
          <div class="formgrid grid">
            <div class="field col-12 md:col-6">
              <label>Nama</label>
              <InputText
                v-model="form.name"
                :class="{ 'p-invalid': v$.form.name.$invalid && submitted }"
                class="w-full"
                input-class="w-full"
                maxlength="50"
              />
              <small
                v-if="
                  (v$.form.name.$invalid && submitted) ||
                  v$.form.name.$pending.$response
                "
                class="p-error"
                >{{ v$.form.name.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-6">
              <label>Username</label>
              <InputText
                v-model="form.username"
                :class="{ 'p-invalid': v$.form.username.$invalid && submitted }"
                class="w-full"
                input-class="w-full"
                maxlength="50"
                autocomplete="off"
              />
              <small
                v-if="
                  (v$.form.username.$invalid && submitted) ||
                  v$.form.username.$pending.$response
                "
                class="p-error"
                >{{ v$.form.username.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-6">
              <label>Password *</label>
              <Password
                v-model="form.password"
                :toggleMask="true"
                weakLabel="Password lemah"
                mediumLabel="Password cukup kuat"
                strongLabel="Password sangat kuat"
                class="w-full"
                input-class="w-full"
                autocomplete="new-password"
              />
            </div>
            <div class="field col-12 md:col-6">
              <label :class="{ 'p-error': v$.form.email.$invalid && submitted }"
                >Email *</label
              >
              <InputText
                v-model="v$.form.email.$model"
                type="text"
                class="w-full"
                :class="{ 'p-invalid': v$.form.email.$invalid && submitted }"
                maxlength="100"
              />
              <span v-if="v$.form.email.$error && submitted">
                <span
                  id="email-error"
                  v-for="(error, index) of v$.form.email.$errors"
                  :key="index"
                >
                  <small class="p-error">{{ error.$message }}</small>
                </span>
              </span>
              <small
                v-else-if="
                  (v$.form.email.$invalid && submitted) ||
                  v$.form.email.$pending.$response
                "
                class="p-error"
                >{{ v$.form.email.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-12">
              <label>Role User</label>
              <Dropdown
                v-model="form.role"
                :options="listRole"
                optionValue="id"
                optionLabel="name"
                placeholder="Pilih Role"
                class="w-full"
                input-class="w-full"
                :showClear="true"
                :class="{ 'p-invalid': v$.form.role.$invalid && submitted }"
              >
                <small
                  v-if="
                    (v$.form.role.$invalid && submitted) ||
                    v$.form.role.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.role.required.$message }}</small
                >
              </Dropdown>
              <small
                v-if="
                  (v$.form.role.$invalid && submitted) ||
                  v$.form.role.$pending.$response
                "
                class="p-error"
                >{{ v$.form.role.required.$message }}</small
              >
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required, email } from '@vuelidate/validators'
import Password from 'primevue/password'

export default {
  components: {
    Password,
  },
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    listRole: {
      type: Array,
      default() {
        return []
      },
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true
      console.log(isFormValid)

      if (!isFormValid) {
        return
      }

      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        name: {
          required: helpers.withMessage('Nama harus diisi.', required),
        },
        username: {
          required: helpers.withMessage('Username harus diisi.', required),
        },
        email: {
          required: helpers.withMessage('Email harus diisi.', required),
          email: helpers.withMessage('Format email tidak valid.', email),
        },
        role: {
          required: helpers.withMessage('Role harus dipilih.', required),
        },
      },
    }
  },
}
</script>
