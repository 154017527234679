import http from '../clients/Axios'

export default class RolePermissionService {
  async get(params = '') {
    const res = await http.get(`/roles${params}`)
    return res
  }

  async getByIdRoles(param) {
    param = param ? param : ''
    const res = await http.get(`/roles/${param}`)
    return res
  }



  async getPermission(params = '') {
    const res = await http.get(`/permissions${params}`)
    return res
  }

  async getByIdPermission(param) {
    param = param ? param : ''
    const res = await http.get(`/permissions/${param}`)
    return res
  }

  async addRolePermission(form) {
    const res = await http.post('/roles', form)
    return res
  }

  async updateRolePermission(id, form) {
    const res = await http.put(`/roles/${id}`, form)
    return res
  }
}
